.collection-inner {
  width: 97%;
  display: flex;
  justify-content: space-around;
}

.collection-inner-title {
  text-align: center;
}

.collection-inner__img {
  padding: 1.25rem;
  width: 50%;
  text-align: center;
}
.collection-inner__img img {
  max-width: 100%;
  max-height: 60vh;
}
.btn-navigation.btn-navigation--collection {
  right: 20px;
  left: unset;
}

.collection-inner__information {
  text-align: center;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.collection-inner__information p {
  width: 80%;
}

@media (max-width: 1100px) {
  .collection-inner {
    flex-direction: column;
    align-items: center;
  }
  .collection-inner__img {
    width: 70%;
  }
  .collection-inner__information {
    width: 70%;
  }
}

@media (max-width: 720px) {
  .collection-inner__img {
    width: 80%;
  }
  .collection-inner__information {
    width: 80%;
  }
}
