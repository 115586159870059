.navigation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.navigation__user {
  margin-bottom: 0;
  margin-top: 5rem;
}

.navigation__menu {
  list-style: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-inline-start: 0px;
  margin-top: 0;
  padding: 0.625rem;
}

.navigation__menu .btn {
  display: block;
  width: 100%;
}

.link.link-collection {
  color: #05171f;
}
