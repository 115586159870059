.credits {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.credits__menu {
  list-style: none;
  border: 2px solid rgba(0, 0, 0, 0.516);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.294);
  border-radius: 10px;
  display: flex;
  margin: 0.625rem;
  flex-direction: column;
  padding-inline-start: 0px;
  padding: 1.25rem;
}

.menu-first-title {
  margin-top: 0;
}

.credits__menu h4 {
  margin-bottom: 0.938rem;
}
