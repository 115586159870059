.homepage-navigation {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}
.homepage-navigation__explanation {
  margin-top: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.516);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.294);
  border-radius: 10px;
  padding: 0.5rem;
  text-align: center;
  max-width: 400px;
}
.btn.btn-homepage {
  text-align: center;
  display: block;
}
.homepage-navigation__explanation p {
  margin: 0.5rem;
}

@media (max-width: 1100px) {
  .homepage-navigation {
    padding-top: 5rem;
  }
}

@media (max-width: 720px) {
  .homepage-navigation {
    justify-content: flex-start;
    padding-top: 5rem;
  }
}
