@import url('https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap');

.homepage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
}

.homepage-text {
  border: 1px solid rgba(0, 0, 0, 0.516);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.294);
  border-radius: 10px;
  text-align: center;
  padding: 1rem 1rem 0rem 1rem;
  max-width: 70%;
}

.homepage-text p {
  margin-top: 0;
}

.perex {
  color: #1f6101;
  text-align: center;
}

.title {
  font-family: 'Charm', cursive;
  font-size: 3rem;
  margin: 1rem;
  color: #1f6101;
}

@media (max-width: 1100px) {
  .homepage {
    padding-top: 5rem;
  }
}

@media (max-width: 720px) {
  .homepage {
    justify-content: flex-start;
    padding-top: 5rem;
  }
}
