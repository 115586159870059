.question {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 3rem;
}

.question-box {
  width: 100%;
  padding: 5%;
}

.question-box--question {
  font-weight: bold;
}

.answers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (max-width: 1100px) {
  .question {
    flex-direction: column;
    font-size: 2rem;
    align-items: center;
  }
  .question-box {
    padding: 2%;
    width: 80%;
  }
  .answers {
    padding-bottom: 4.2rem;
  }
}

@media (max-width: 510px) {
  .question {
    font-size: 1.5rem;
    padding-top: 3.75rem;
  }
  .answers {
    padding-bottom: 4.2rem;
  }
}
