.ended {
  margin: 0;
  text-align: center;
  border-radius: 20px;
  box-shadow: 7px 8px 26px 0px rgba(255, 255, 255, 0.6);
  height: 600px;
  overflow: hidden;
}

.winners {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
