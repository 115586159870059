.answered-window-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 37.5rem;
  margin: auto;
  padding: 2.9rem 0.625rem;
}

.answered-window-wrapper img {
  border-radius: 10px;
  width: 80%;
}

.answered-window-wrapper .points {
  color: #1f6101;
  font-weight: bold;
}

.animal-information {
  width: 80%;
  text-align: center;
}

.next {
  text-align: center;
}

.btn-next {
  cursor: pointer;
  width: 250px;
  padding: 0.625rem 1.25rem;
  color: white;
  background-color: #749303;
  font-size: 1rem;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  border: 1px solid #749303;
  border-radius: 10px;
}
