@import url(https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap);
.homepage {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.625rem;
}

.homepage-text {
  border: 1px solid rgba(0, 0, 0, 0.516);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.294);
  border-radius: 10px;
  text-align: center;
  padding: 1rem 1rem 0rem 1rem;
  max-width: 70%;
}

.homepage-text p {
  margin-top: 0;
}

.perex {
  color: #1f6101;
  text-align: center;
}

.title {
  font-family: 'Charm', cursive;
  font-size: 3rem;
  margin: 1rem;
  color: #1f6101;
}

@media (max-width: 1100px) {
  .homepage {
    padding-top: 5rem;
  }
}

@media (max-width: 720px) {
  .homepage {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-top: 5rem;
  }
}

.user-profile {
  width: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 0.625rem;
}

.user-profile__table {
  width: 50%;
  text-align: center;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
}

.user-profile__table thead tr {
  background-color: #1f6101;
  color: white;
  text-align: center;
}
.user-profile__table th,
.user-profile__table td {
  padding: 0.938rem;
}

.user-profile__table tbody tr {
  border-bottom: 1px solid #05171f;
}

.user-profile__table tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.user-profile__table tbody tr:last-of-type {
  border-bottom: 2px solid #1f6101;
}

.wins {
  color: #1f6101;
}
.losses {
  color: rgba(190, 14, 14, 0.746);
}

.bold {
  font-weight: bold;
}

.clicable {
  cursor: pointer;
}
.clicable:hover {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .user-profile {
    padding-top: 5rem;
  }
}

@media (max-width: 720px) {
  .user-profile {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-top: 5rem;
  }
  .user-profile__table {
    width: 85%;
  }
}

.user-signup-navigation {
  text-align: center;
}

.collection {
  width: 100%;
  height: 100%;
}

.collection__items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.collection-item {
  width: 23%;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid #05171f;
  border-radius: 10px;
}

.collection-item--collected {
  cursor: pointer;
}

.collection__title {
  text-align: center;
}

.collection-item__image {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  border: 1px solid #05171f;
}
.collection-item__title {
  width: 100%;
  margin: 0;
  text-decoration: none;
  text-align: center;
  background-color: white;
  padding: 0.625rem;
  border-radius: 0 0 10px 10px;
}

.collection-item__title.mystery {
  padding: 0.3rem;
}

@media (max-width: 1450px) {
  .collection-item {
    width: 30%;
  }
}
@media (max-width: 1100px) {
  .collection-item {
    width: 45%;
  }
}
@media (max-width: 720px) {
  .collection__items {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .collection-item {
    width: 60%;
  }
  .collection-item__image {
    width: 100%;
  }
}

@media (max-width: 510px) {
  .collection__items {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .collection-item {
    width: 80%;
  }

  .collection-item__image {
    height: 170px;
  }
}

.main-map {
  width: 1400px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn.btn-answer {
  padding: 1.4rem;
  font-size: 1.25rem;
  font-weight: bold;
  width: 100%;
  background-color: white;
  color: black;
}

.btn.btn-answer:hover,
.btn.btn-answer:active,
.btn.btn-answer:focus {
  background-color: #749303;
}

.btn.btn-answer:disabled {
  background-color: rgb(172, 169, 169);
  border: 1px solid rgb(172, 169, 169);
  color: white;
  text-decoration: line-through;
}

@media (max-width: 1100px) {
  .btn.btn-answer {
    font-size: 1.125rem;
    padding: 1.5rem;
    width: 80%;
  }
}

.answered-window-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100%;
  max-width: 37.5rem;
  margin: auto;
  padding: 2.9rem 0.625rem;
}

.answered-window-wrapper img {
  border-radius: 10px;
  width: 80%;
}

.answered-window-wrapper .points {
  color: #1f6101;
  font-weight: bold;
}

.animal-information {
  width: 80%;
  text-align: center;
}

.next {
  text-align: center;
}

.btn-next {
  cursor: pointer;
  width: 250px;
  padding: 0.625rem 1.25rem;
  color: white;
  background-color: #749303;
  font-size: 1rem;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  border: 1px solid #749303;
  border-radius: 10px;
}

.btn-audio {
  margin-top: 1.5rem;
  cursor: pointer;
  text-align: center;
  width: 65px;
  height: 60px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #1f6101;
  color: #1f6101;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
  transition: background-color 200ms linear, color 200ms linear;
}
.btn-audio:hover {
  background-color: #1f6101;
  color: white;
}

.audio-bar {
  width: 80%;
  border: 1px solid #05171f;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
}

.audio-bar--inner {
  background-color: #1f6101;
  height: 0.625rem;
  color: white;
  border-radius: 10px;
}

.question {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: center;
  font-size: 3rem;
}

.question-box {
  width: 100%;
  padding: 5%;
}

.question-box--question {
  font-weight: bold;
}

.answers {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

@media (max-width: 1100px) {
  .question {
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 2rem;
    -webkit-align-items: center;
            align-items: center;
  }
  .question-box {
    padding: 2%;
    width: 80%;
  }
  .answers {
    padding-bottom: 4.2rem;
  }
}

@media (max-width: 510px) {
  .question {
    font-size: 1.5rem;
    padding-top: 3.75rem;
  }
  .answers {
    padding-bottom: 4.2rem;
  }
}

.ended {
  margin: 0;
  text-align: center;
  border-radius: 20px;
  box-shadow: 7px 8px 26px 0px rgba(255, 255, 255, 0.6);
  height: 600px;
  overflow: hidden;
}

.winners {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.game {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1400px;
}

.game-image {
  display: block;
  position: absolute;
}

.game-image--clickable {
  cursor: pointer;
  width: 70px;
}

.game-image--tile {
  width: 45px;
}

.game-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.game-icon {
  color: #05171f;
  margin-right: 3px;
}

.game-icon--heart {
  color: red;
}
.user-stats {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
}

.user-stats p {
  margin: 0.6rem;
}

.user-collection {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-align: left;
}

.user-collection p {
  margin: 0.6rem;
}
.user-name {
  font-weight: bold;
}

.popup {
  background-color: #1f6101;
  color: white;
  text-align: center;
  padding: 0.188rem;
  position: absolute;
  display: none;
  font-weight: bold;
  border: 1px solid #05171f;
  border-radius: 10px;
  -webkit-transform: translate(-100%, 0%);
          transform: translate(-100%, 0%);
}
.user-stats__lives:hover ~ .popup-lives,
.user-stats__hints:hover ~ .popup-hints,
.user-collection__points:hover ~ .popup-points,
.user-collection__collection:hover ~ .popup-collection {
  display: block;
}

.popup-lives {
  top: 36px;
}
.popup-hints {
  top: 68px;
}

.popup-points {
  top: 5px;
}
.popup-collection {
  top: 40px;
}

.top-winners {
  width: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 0.625rem;
}

.top-winners__table {
  width: 50%;
  text-align: center;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
}

.top-winners__table thead tr {
  background-color: #1f6101;
  color: white;
  text-align: center;
}
.top-winners__table thead tr:first-child {
  border-bottom: 2px solid white;
}
.top-winners__table th,
.top-winners__table td {
  padding: 0.938rem;
}

.top-winners__table tbody tr {
  border-bottom: 1px solid #05171f;
}

.top-winners__table tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.top-winners__table tbody tr:last-child {
  border-bottom: 2px solid #1f6101;
}

.winners__winner--current {
  color: #1f6101;
  font-weight: bold;
}

@media (max-width: 1100px) {
  .top-winners {
    padding-top: 5rem;
  }
}

@media (max-width: 720px) {
  .top-winners {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-top: 5rem;
  }
  .top-winners__table {
    width: 85%;
  }
}

.navigation {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.navigation__user {
  margin-bottom: 0;
  margin-top: 5rem;
}

.navigation__menu {
  list-style: none;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  margin-top: 0;
  padding: 0.625rem;
}

.navigation__menu .btn {
  display: block;
  width: 100%;
}

.link.link-collection {
  color: #05171f;
}

.homepage-navigation {
  width: 100%;
  min-height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 3rem;
}
.homepage-navigation__explanation {
  margin-top: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.516);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.294);
  border-radius: 10px;
  padding: 0.5rem;
  text-align: center;
  max-width: 400px;
}
.btn.btn-homepage {
  text-align: center;
  display: block;
}
.homepage-navigation__explanation p {
  margin: 0.5rem;
}

@media (max-width: 1100px) {
  .homepage-navigation {
    padding-top: 5rem;
  }
}

@media (max-width: 720px) {
  .homepage-navigation {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-top: 5rem;
  }
}

.credits {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.credits__menu {
  list-style: none;
  border: 2px solid rgba(0, 0, 0, 0.516);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.294);
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  margin: 0.625rem;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  padding: 1.25rem;
}

.menu-first-title {
  margin-top: 0;
}

.credits__menu h4 {
  margin-bottom: 0.938rem;
}

.btn-navigation.btn-navigation--home {
  right: 20px;
  left: unset;
}

.collection-inner {
  width: 97%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.collection-inner-title {
  text-align: center;
}

.collection-inner__img {
  padding: 1.25rem;
  width: 50%;
  text-align: center;
}
.collection-inner__img img {
  max-width: 100%;
  max-height: 60vh;
}
.btn-navigation.btn-navigation--collection {
  right: 20px;
  left: unset;
}

.collection-inner__information {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  width: 50%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.collection-inner__information p {
  width: 80%;
}

@media (max-width: 1100px) {
  .collection-inner {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .collection-inner__img {
    width: 70%;
  }
  .collection-inner__information {
    width: 70%;
  }
}

@media (max-width: 720px) {
  .collection-inner__img {
    width: 80%;
  }
  .collection-inner__information {
    width: 80%;
  }
}

html {
  box-sizing: border-box;
}
*,
::after,
::before {
  box-sizing: inherit;
}

body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  width: 100vw;
  background-image: url(/static/media/backbround.4e079ed0.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #05171f;
  font-size: 1.1rem;
}
.overflow {
  overflow: auto;
}
.overflow::-webkit-scrollbar {
  width: 10px;
}
.overflow::-webkit-scrollbar-track {
  background: transparent;
}
.overflow::-webkit-scrollbar-thumb {
  background: #1f6101;
  border-radius: 10px;
}

.main {
  width: 1404px;
  height: 604px;
  background-color: rgba(255, 255, 255, 0.63);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 7px 8px 26px 0px rgba(255, 255, 255, 0.6);
  max-width: 1404px;
  border: 2px solid #05171f;
  position: relative;
}

.base_form_wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
}

.base_form_wrapper h2 {
  color: #1f6101;
}

.base_form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 400px;
  padding: 1.25rem;
  border: 2px solid rgba(0, 0, 0, 0.516);

  background-color: rgba(0, 0, 0, 0.029);
  border-radius: 10px;
  margin-bottom: 0.625rem;
}
.base_form.base_form--update-profile {
  width: 97%;
}

.base_form .base_form__box {
  position: relative;
  margin-bottom: 1.25rem;
}

.base_form .base_form__box input {
  width: 100%;
  padding: 0.625rem 0;
  font-size: 1rem;
  color: #1f6101;
  border: none;
  border-bottom: 2px solid #a3c232;
  outline: none;
  background: transparent;
}

.base_form .base_form__box input:focus {
  border-bottom: 2px solid #1f6101;
}

.base_form .base_form__box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.625rem 0;
  font-size: 1rem;
  color: #1f6101;
  pointer-events: none;
  transition: 0.5s;
}

.base_form .base_form__box input:focus ~ label,
.base_form .base_form__box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #1f6101;
  font-size: 0.75rem;
}
.base_form .base_form__box input:not(:-ms-input-placeholder):invalid ~ label {
  top: -20px;
  left: 0;
  color: rgb(201, 13, 13);
  font-size: 0.75rem;
}
.base_form .base_form__box input:not(:placeholder-shown):invalid ~ label {
  top: -20px;
  left: 0;
  color: rgb(201, 13, 13);
  font-size: 0.75rem;
}

.base_form button {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 0.625rem 1.25rem;
  color: white;
  background-color: #749303;
  font-size: 1rem;
  text-transform: uppercase;
  transition: background-color 200ms linear;
  letter-spacing: 4px;
  border: 1px solid #749303;
  border-radius: 10px;
}

.base_form button:hover {
  background-color: #1f6101;
  border: 1px solid #1f6101;
}

.base_form button:disabled {
  background-color: rgb(172, 169, 169);
  border: rgb(172, 169, 169);
  cursor: not-allowed;
}

.base_form .error {
  display: none;
  color: rgb(201, 13, 13);
  margin-top: 0.125rem;
  font-size: 0.75rem;
}

.base_form .error-message {
  color: rgb(201, 13, 13);
  margin-top: 0.125rem;
  font-size: 0.75rem;
  text-align: center;
}

.base_form .base_form__box input:not(:-ms-input-placeholder):invalid ~ .error {
  display: block;
}

.base_form .base_form__box input:not(:placeholder-shown):invalid ~ .error {
  display: block;
}

.link {
  display: inline-block;
  color: #749303;
  padding: 0.188rem;
  margin-bottom: 0.188rem;
}
.link.link-left-space {
  margin-left: 0.313rem;
}

.link:hover,
.link:active,
.link:focus {
  color: #1f6101;
}

.btn {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1.875rem;
  padding: 0.625rem 1.25rem;
  color: white;
  background-color: #749303;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  border: 1px solid #749303;
  border-radius: 10px;
  transition: background-color 200ms linear;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
}

.btn:hover,
.btn:active,
.btn:focus {
  background-color: #1f6101;
}

.btn-navigation {
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  width: 65px;
  height: 60px;
  margin: 0;
  padding: 0;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #1f6101;
  color: #1f6101;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
  transition: background-color 200ms linear, color 200ms linear;
}
.btn-navigation:hover {
  background-color: #1f6101;
  color: white;
}

@media (max-width: 1450px) {
  .main {
    width: 1000px;
  }
}

@media (max-width: 1100px) {
  .main {
    width: 700px;
  }
}

@media (max-width: 720px) {
  .main {
    width: 500px;
  }
}

@media (max-width: 510px) {
  .main {
    width: 360px;
  }
}

@media (max-width: 360px) {
  .main {
    width: 320px;
  }
}

