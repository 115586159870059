@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
html {
  box-sizing: border-box;
}
*,
::after,
::before {
  box-sizing: inherit;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  width: 100vw;
  background-image: url('./images/backbround.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: #05171f;
  font-size: 1.1rem;
}
.overflow {
  overflow: auto;
}
.overflow::-webkit-scrollbar {
  width: 10px;
}
.overflow::-webkit-scrollbar-track {
  background: transparent;
}
.overflow::-webkit-scrollbar-thumb {
  background: #1f6101;
  border-radius: 10px;
}

.main {
  width: 1404px;
  height: 604px;
  background-color: rgba(255, 255, 255, 0.63);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 7px 8px 26px 0px rgba(255, 255, 255, 0.6);
  max-width: 1404px;
  border: 2px solid #05171f;
  position: relative;
}

.base_form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.base_form_wrapper h2 {
  color: #1f6101;
}

.base_form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 1.25rem;
  border: 2px solid rgba(0, 0, 0, 0.516);

  background-color: rgba(0, 0, 0, 0.029);
  border-radius: 10px;
  margin-bottom: 0.625rem;
}
.base_form.base_form--update-profile {
  width: 97%;
}

.base_form .base_form__box {
  position: relative;
  margin-bottom: 1.25rem;
}

.base_form .base_form__box input {
  width: 100%;
  padding: 0.625rem 0;
  font-size: 1rem;
  color: #1f6101;
  border: none;
  border-bottom: 2px solid #a3c232;
  outline: none;
  background: transparent;
}

.base_form .base_form__box input:focus {
  border-bottom: 2px solid #1f6101;
}

.base_form .base_form__box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.625rem 0;
  font-size: 1rem;
  color: #1f6101;
  pointer-events: none;
  transition: 0.5s;
}

.base_form .base_form__box input:focus ~ label,
.base_form .base_form__box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #1f6101;
  font-size: 0.75rem;
}
.base_form .base_form__box input:not(:placeholder-shown):invalid ~ label {
  top: -20px;
  left: 0;
  color: rgb(201, 13, 13);
  font-size: 0.75rem;
}

.base_form button {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 0.625rem 1.25rem;
  color: white;
  background-color: #749303;
  font-size: 1rem;
  text-transform: uppercase;
  transition: background-color 200ms linear;
  letter-spacing: 4px;
  border: 1px solid #749303;
  border-radius: 10px;
}

.base_form button:hover {
  background-color: #1f6101;
  border: 1px solid #1f6101;
}

.base_form button:disabled {
  background-color: rgb(172, 169, 169);
  border: rgb(172, 169, 169);
  cursor: not-allowed;
}

.base_form .error {
  display: none;
  color: rgb(201, 13, 13);
  margin-top: 0.125rem;
  font-size: 0.75rem;
}

.base_form .error-message {
  color: rgb(201, 13, 13);
  margin-top: 0.125rem;
  font-size: 0.75rem;
  text-align: center;
}

.base_form .base_form__box input:not(:placeholder-shown):invalid ~ .error {
  display: block;
}

.link {
  display: inline-block;
  color: #749303;
  padding: 0.188rem;
  margin-bottom: 0.188rem;
}
.link.link-left-space {
  margin-left: 0.313rem;
}

.link:hover,
.link:active,
.link:focus {
  color: #1f6101;
}

.btn {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1.875rem;
  padding: 0.625rem 1.25rem;
  color: white;
  background-color: #749303;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  border: 1px solid #749303;
  border-radius: 10px;
  transition: background-color 200ms linear;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
}

.btn:hover,
.btn:active,
.btn:focus {
  background-color: #1f6101;
}

.btn-navigation {
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
  width: 65px;
  height: 60px;
  margin: 0;
  padding: 0;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #1f6101;
  color: #1f6101;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
  transition: background-color 200ms linear, color 200ms linear;
}
.btn-navigation:hover {
  background-color: #1f6101;
  color: white;
}

@media (max-width: 1450px) {
  .main {
    width: 1000px;
  }
}

@media (max-width: 1100px) {
  .main {
    width: 700px;
  }
}

@media (max-width: 720px) {
  .main {
    width: 500px;
  }
}

@media (max-width: 510px) {
  .main {
    width: 360px;
  }
}

@media (max-width: 360px) {
  .main {
    width: 320px;
  }
}
