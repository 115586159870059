.btn-audio {
  margin-top: 1.5rem;
  cursor: pointer;
  text-align: center;
  width: 65px;
  height: 60px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #1f6101;
  color: #1f6101;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
  transition: background-color 200ms linear, color 200ms linear;
}
.btn-audio:hover {
  background-color: #1f6101;
  color: white;
}

.audio-bar {
  width: 80%;
  border: 1px solid #05171f;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
}

.audio-bar--inner {
  background-color: #1f6101;
  height: 0.625rem;
  color: white;
  border-radius: 10px;
}
