.game {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1400px;
}

.game-image {
  display: block;
  position: absolute;
}

.game-image--clickable {
  cursor: pointer;
  width: 70px;
}

.game-image--tile {
  width: 45px;
}

.game-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.game-icon {
  color: #05171f;
  margin-right: 3px;
}

.game-icon--heart {
  color: red;
}
.user-stats {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
}

.user-stats p {
  margin: 0.6rem;
}

.user-collection {
  position: absolute;
  right: 10px;
  bottom: 10px;
  text-align: left;
}

.user-collection p {
  margin: 0.6rem;
}
.user-name {
  font-weight: bold;
}

.popup {
  background-color: #1f6101;
  color: white;
  text-align: center;
  padding: 0.188rem;
  position: absolute;
  display: none;
  font-weight: bold;
  border: 1px solid #05171f;
  border-radius: 10px;
  transform: translate(-100%, 0%);
}
.user-stats__lives:hover ~ .popup-lives,
.user-stats__hints:hover ~ .popup-hints,
.user-collection__points:hover ~ .popup-points,
.user-collection__collection:hover ~ .popup-collection {
  display: block;
}

.popup-lives {
  top: 36px;
}
.popup-hints {
  top: 68px;
}

.popup-points {
  top: 5px;
}
.popup-collection {
  top: 40px;
}
