.btn.btn-answer {
  padding: 1.4rem;
  font-size: 1.25rem;
  font-weight: bold;
  width: 100%;
  background-color: white;
  color: black;
}

.btn.btn-answer:hover,
.btn.btn-answer:active,
.btn.btn-answer:focus {
  background-color: #749303;
}

.btn.btn-answer:disabled {
  background-color: rgb(172, 169, 169);
  border: 1px solid rgb(172, 169, 169);
  color: white;
  text-decoration: line-through;
}

@media (max-width: 1100px) {
  .btn.btn-answer {
    font-size: 1.125rem;
    padding: 1.5rem;
    width: 80%;
  }
}
