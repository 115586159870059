.user-profile {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.625rem;
}

.user-profile__table {
  width: 50%;
  text-align: center;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.294);
}

.user-profile__table thead tr {
  background-color: #1f6101;
  color: white;
  text-align: center;
}
.user-profile__table th,
.user-profile__table td {
  padding: 0.938rem;
}

.user-profile__table tbody tr {
  border-bottom: 1px solid #05171f;
}

.user-profile__table tbody tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.user-profile__table tbody tr:last-of-type {
  border-bottom: 2px solid #1f6101;
}

.wins {
  color: #1f6101;
}
.losses {
  color: rgba(190, 14, 14, 0.746);
}

.bold {
  font-weight: bold;
}

.clicable {
  cursor: pointer;
}
.clicable:hover {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .user-profile {
    padding-top: 5rem;
  }
}

@media (max-width: 720px) {
  .user-profile {
    justify-content: flex-start;
    padding-top: 5rem;
  }
  .user-profile__table {
    width: 85%;
  }
}
