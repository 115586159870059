.collection {
  width: 100%;
  height: 100%;
}

.collection__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.collection-item {
  width: 23%;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid #05171f;
  border-radius: 10px;
}

.collection-item--collected {
  cursor: pointer;
}

.collection__title {
  text-align: center;
}

.collection-item__image {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  border: 1px solid #05171f;
}
.collection-item__title {
  width: 100%;
  margin: 0;
  text-decoration: none;
  text-align: center;
  background-color: white;
  padding: 0.625rem;
  border-radius: 0 0 10px 10px;
}

.collection-item__title.mystery {
  padding: 0.3rem;
}

@media (max-width: 1450px) {
  .collection-item {
    width: 30%;
  }
}
@media (max-width: 1100px) {
  .collection-item {
    width: 45%;
  }
}
@media (max-width: 720px) {
  .collection__items {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .collection-item {
    width: 60%;
  }
  .collection-item__image {
    width: 100%;
  }
}

@media (max-width: 510px) {
  .collection__items {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .collection-item {
    width: 80%;
  }

  .collection-item__image {
    height: 170px;
  }
}
